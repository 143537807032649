import { FC, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { getAuthorFullName } from '@/helpers/helpers';
import { IBookPage } from '@/interfaces/booksPage.interface';
import { IGenresData } from '@/interfaces/genres.interface';
import styles from './Menu.module.scss';

interface IMenuProps {
  isMobileMenu: boolean;
  setIsMobileMenu: (isMobileMenu: boolean) => void;
  menuNew: IBookPage;
  menuGenres: IGenresData[];
}

const Menu: FC<IMenuProps> = ({ isMobileMenu, setIsMobileMenu, menuNew, menuGenres }) => {
  const router = useRouter();

  const [isSuperGenres, setIsSuperGenres] = useState(false);
  const [inputSearch, setInputSearch] = useState('');

  const setMenuModeHandle = () => {
    setIsMobileMenu(!isMobileMenu);
  };

  const getSearchWord = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputSearch(event.target.value);
  };

  const searchByPressEnter = (event: React.KeyboardEvent<object>) => {
    if (String(event.code) == 'Enter') {
      router.push(`/search_advanced/book/${searchWord}`);
    }
  };

  const searchWord = inputSearch.includes(' ') ? inputSearch.split(' ').join('+') : inputSearch;

  return (
    <>
      <div className={styles.mainContainer__menuBtn} onClick={setMenuModeHandle}>
        <div className={styles.burger}>
          <span className={styles.burger__topper}></span>
          <span className={styles.burger__bottom}></span>
          <span className={styles.burger__footer}></span>
        </div>
      </div>

      <div className={styles.logotype}>
        <Link prefetch={false} className={styles.logotype__link} href="/">
          <img width="93" height="103" className={styles.logotype__img} src="/logo-desktop.svg" alt="logo" />
          <img width="110" height="36" className={`${styles.logotype__img} ${styles.logotype__img_mob}`} src="/logo-mobile.svg" alt="logo" />
        </Link>
      </div>

      <div className={styles.searchForm}>
        <input value={inputSearch} onChange={getSearchWord} onKeyDown={searchByPressEnter} className={`${styles.searchForm__input} ${styles.input}`} name="query" type="text" placeholder="Найти" />
        {!!inputSearch.length ? <Link prefetch={false} className={styles.searchForm__button} href={`/search_advanced/book/${searchWord}`}></Link> : <span className={styles.searchForm__button}></span>}
      </div>

      <ul className={`${styles.menuList} ${styles.menuList_main} ${styles.menuList_mobToggle}`}>
        <li className={styles.menuList__item}>
          <Link prefetch={false} href="/library/dir/all/new">
            Новинки
          </Link>
        </li>
        <li className={styles.menuList__item}>
          <Link prefetch={false} href="/library/dir/all/popular">
            Популярные книги
          </Link>
        </li>
        <li className={styles.menuList__item}>
          <Link prefetch={false} href="/library/audiobooks/all">
            Популярные аудиокниги
          </Link>
        </li>
        <li className={styles.menuList__item}>
          <Link prefetch={false} href="/library/dir/all/hits">
            Популярные авторы
          </Link>
        </li>
        {/* Временно скрываем */}
        {/* <li className={styles.menuList__item}>
          <Link prefetch={false} href="/library/series">
            Серии книг
          </Link>
        </li> */}
        {/* <li className={styles.menuList__item}>
          <Link prefetch={false} href="/blog">
            Блог
          </Link>
        </li> */}
        <li className={styles.menuList__item}>
          <Link prefetch={false} href="/advices/1">
            Советы
          </Link>
        </li>
      </ul>

      <ul className={`${styles.menuList} ${styles.menuList_border} ${styles.menuList_mobToggle}`}>
        <li className={`${styles.menuList__item} ${styles.menuList__item_title}`}>
          <Link prefetch={false} href="/library/dir/all/genre">
            Жанры
          </Link>
        </li>

        {menuGenres && menuGenres.length && (
          <>
            {menuGenres.slice(0, 7).map((genre) => (
              <li key={genre.id} className={styles.menuList__item}>
                <Link prefetch={false} href={`/library/dir/all/genre/${genre.alias}`}>
                  {genre.title}
                </Link>
              </li>
            ))}
            <li onClick={() => setIsSuperGenres(!isSuperGenres)} className={isSuperGenres ? `${styles.menuList__item} ${styles.menuList__item_toggle} ${styles.active}` : `${styles.menuList__item} ${styles.menuList__item_toggle}`}>
              Еще жанры
            </li>
          </>
        )}

        <ul className={styles.menu_genres}>
          {menuGenres &&
            menuGenres.length &&
            menuGenres.slice(7, 20).map((genre) => (
              <li key={genre.id} className={styles.menuList__item}>
                <Link prefetch={false} href={`/library/dir/all/genre/${genre.alias}`}>
                  {genre.title}
                </Link>
              </li>
            ))}
        </ul>
      </ul>

      <ul className={`${styles.menuList} ${styles.menuList_border} ${styles.menuList_mobNone}`}>
        <li className={`${styles.menuList__item} ${styles.menuList__item_title}`}>
          <Link prefetch={false} href="/library/dir/all/new">
            Новинки
          </Link>
        </li>

        {menuNew && menuNew.data.length && (
          <>
            {menuNew.data.map((newBook) => (
              <li key={newBook.id} className={styles.menuList__item}>
                <Link prefetch={false} href={`/book/${newBook.id}`}>{`${getAuthorFullName(newBook.author.first_name, newBook.author.middle_name, newBook.author.last_name)} - ${newBook.book.title}`}</Link>
              </li>
            ))}
            <li className={`${styles.menuList__item} ${styles.menuList__item_link}`}>
              <Link prefetch={false} href="/library/dir/all/new">
                Все новинки
              </Link>
            </li>
          </>
        )}
      </ul>

      <ul className={`${styles.menuList} ${styles.menuList_border} ${styles.menuList_mobToggle}`}>
        <li className={`${styles.menuList__item} ${styles.menuList__item_title} ${styles.menuList__item_titleFeedBack}`}>
          <Link prefetch={false} href="/help">
            Обратная связь
          </Link>
        </li>
        <li className={styles.menuList__item}>
          <Link prefetch={false} href="/links/faq">
            Частые вопросы
          </Link>
        </li>
        <li className={styles.menuList__item}>
          <Link prefetch={false} href="/links/privacy">
            Политика приватности данных
          </Link>
        </li>
        <li className={styles.menuList__item}>
          Наша почта:{' '}
          <Link prefetch={false} target="_blanc" className="ref" href="mailto:info@fenzin.org">
            <u>info@fenzin.org</u>
          </Link>
        </li>
      </ul>
    </>
  );
};

export default Menu;
